<template>
  <div class="accessories-links">
    <div class="h5">Accessories</div>
    <div v-for="(accessory, index) in accessories" :key="index">
      <btn-secondary url="#accessories">{{ accessory }}</btn-secondary>
    </div>
  </div>
</template>

<script>
import BtnSecondary from "@/components/BtnSecondary.vue";

  export default {
    name: "AccessoriesLinks",

    components: {
      BtnSecondary
    },

    props: {
      accessories: Array,
    },
  }
</script>

<style scoped>
.accessories-links {
  margin: 20px 0;
}

.h5 {
  color: var(--gray5);
  margin-bottom: 10px;
}
</style>
