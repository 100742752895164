import { render, staticRenderFns } from "./BtnPrimaryAlt.vue?vue&type=template&id=3872a943&scoped=true&"
import script from "./BtnPrimaryAlt.vue?vue&type=script&lang=js&"
export * from "./BtnPrimaryAlt.vue?vue&type=script&lang=js&"
import style0 from "./BtnPrimaryAlt.vue?vue&type=style&index=0&id=3872a943&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3872a943",
  null
  
)

export default component.exports