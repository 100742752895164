<template>
  <section class="outro">
    <div class="img-wrapper">
      <img
        :src="`${s3}mundo-suite-2@1x.jpg`"
        :srcset="`
          ${s3}mundo-suite-2@1x.jpg 714w,
          ${s3}mundo-suite-2@2x.jpg 1428w,
          ${s3}mundo-suite-2@3x.jpg 2142w,
          ${s3}mundo-suite-2@4x.jpg 2856w,
        `"
        sizes="100vw"
        alt="Photo woman on Chair"
        class="img1"
      />
      <img
        :src="`${s3}mundo-suite-1@1x.jpg`"
        :srcset="`
          ${s3}mundo-suite-1@1x.jpg 297w,
          ${s3}mundo-suite-1@2x.jpg 594w,
          ${s3}mundo-suite-1@3x.jpg 891w,
          ${s3}mundo-suite-1@4x.jpg 1188w,
        `"
        sizes="100vw"
        alt="Photo Mundo product suite"
        class="img2"
      />
      <div class="block" />
    </div>
  </section>
</template>

<script>
  export default {
    name: "Outro",

    props: {
      s3: String
    },
  }
</script>

<style scoped>
.outro {
  background-color: var(--blue-dk);
  padding: 0 32px 110px;
}

.img-wrapper {
  position: relative;
  z-index: 0;
  width: 100%;
  max-width: 720px;
  margin: 0 auto;
}

img {
  display: block;
  object-fit: cover;
  width: 100%;
  max-width: 720px;
  height: calc(100vw * .4027);
  max-height: 316px;
}

.img1 {
  margin-bottom: 10px;
}

.block {
  width: 75%;
  height: calc( 100vw * .2);
  max-height: 157px;
  background-color: var(--olive-lt);
  position: absolute;
  right: -7px;
  bottom: -7px;
  z-index: -10;
}

@media screen and (min-width: 940px) {
  .outro {
    padding: 0 0 110px;
    overflow: hidden;
  }

  .img-wrapper {
    display: flex;
    max-width: 960px;
    justify-content: center;
    gap: 10px;
  }

  img {
    height: auto;
    max-height: 346px;
  }

  .img1 {
    margin-bottom: 0;
    max-width: 230px;
  }

  .block {
    display: block;
    width: 60%;
    height: 170px;
    right: -10px;
    bottom: -10px;
  }
}
</style>
