<template>
  <section class="photo-grid-wrapper">
    <div class="feature-wrapper">
      <div class="photo-grid-feature">
        <zoom-on-hover
          :imgNormal="`${s3}mundo-${prodImgName}-${feature[prodIndex]}@1x.jpg`"
          :imgZoom="`${s3}mundo-${prodImgName}-${feature[prodIndex]}@2x.jpg`"
          scale="2.5"
          alt="Product photo"
        />
      </div>
    </div>
    <div class="photo-grid">
      <slot /> <!-- Photo slot -->
    </div>
  </section>
</template>

<script>
import ZoomOnHover from "@/components/ZoomOnHover.vue";

export default {
  name: "PhotoGrid",

  components: {
    ZoomOnHover
  },

  props: {
    s3: String,
    feature: Array,
    prodIndex: Number,
    prodImgName: String,
  }
}
</script>

<style>
.photo-grid-wrapper {
  max-width: 630px;
  margin: 30px auto;
}

.feature-wrapper {
  position: relative;
}

.photo-grid-feature {
  position: relative;
  margin-bottom: 10px;
}

.photo-grid-feature img {
  width: 100%;
  display: block;
}

.photo-grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(68px, 1fr));
  grid-gap: 8px;
}

@media only screen and (min-width: 600px) {
  .photo-grid-wrapper {
    max-width: 530px;
  }
}

@media only screen and (min-width: 940px) {
  .photo-grid-wrapper {
    display: flex;
    flex-flow: row nowrap;
    margin: 0;
  }

  .feature-wrapper {
    order: 2;
  }

  .photo-grid-feature {
    width: auto;
    height: 400px;
    margin-left: 10px;
    margin-right: 15px;
  }

  .icon {
    position: absolute;
    bottom: 25px;
    right: 25px;
    z-index: 10;
  }

  .photo-grid {
    display: flex;
    flex-flow: column nowrap;
    flex: 0 0 68px;
    order: 1;
  }
}
</style>
