<template>
  <div class="wrapper">
    <div class="accordion" @click="toggle">
      <h4 class="h4">
        <slot name="title"></slot>
      </h4>
      <img src="@/assets/images/plus.svg" alt="Expand" v-show="show === false">
      <img src="@/assets/images/minus.svg" alt="Collapse" v-show="show === true">
    </div>
    <div v-show="show" class="content">
      <slot name="content"></slot>
    </div>
  </div>
</template> 

<script>
  export default {
    name: "ProductAccordion",

    data() {
      return {
        show: false,
      };
    },

    methods: {
      toggle() {
        this.show = !this.show;
      },
    }
  }
</script>

<style scoped>
.accordion {
  position: relative;
  cursor: pointer;
}

.accordion img {
  position: absolute;
  right: 0;
  top: 38%;
}

h4 {
  border-top: 1px solid var(--gray4);
  padding: 10px 0;
}

.wrapper {
  border-bottom: 1px solid var(--gray4);
  margin-bottom: -1px;
}

ul {
  list-style: disc;
}

li {
  margin: 10px 10px 10px 15px;
}

.content {
  margin-bottom: 25px;
}
</style>
