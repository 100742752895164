<template>
  <footer>
    <a href="/" class="logo">
      <img src="@/assets/images/logo-mundo-h.svg" alt="Mundo by Balanced Body logo">
    </a>
    <div class="links">
      <a href="https://s3.amazonaws.com/s3.pilates.com/resources/6-15-21_BB-Mundo_Warranty_FINAL.pdf" target="_blank" class="p2">Mundo Warranty</a>
      <a href="" @click.prevent="$refs.privacy.openModal()" class="p2">Privacy Policy</a>
      <a href="" @click.prevent="$emit('open-modal')" class="p2">Contact Us</a>
    </div>
    <app-modal ref="privacy">
      <template v-slot:header />
      <template v-slot:body>
        <p><b><u>Mundo Privacy Policy</u></b></p>

        <p>Mundo is a customer-focused company. We collect and keep information in order to show you content that we think will interest you, and so we can better serve you when you contact us.</p>

        <p>We protect personal information using industry-standard best practices like firewalls and Secure Socket Layer communication, and we do not share your information except as outlined in our privacy policy. We do not sell your information. You have rights relevant to your data; for example, to rectify it if it is incorrect, to request a copy of the information we have related to you, and to withdraw consent for us to store and use your information even if you have previously consented. For a full list of your rights and our responsibilities please read our full privacy policy.</p>

        <p>Privacy Policy Effective date: January 1, 2021</p>

        <p>Mundo by Balanced Body, Inc. (“Mundo”, “us”, “we”, or “our”) operates pilatesmundo.com (the “Website”).</p>

        <p>This Privacy Policy informs you of our policies and your rights regarding our collection, use, processing, and storage of your “Personal Data” when you register to purchase goods or services through our Website, sign up to be on our mailing list, or browse our Website.</p>

        <p>As used in this Privacy Policy, “Personal data” means any information that relates to an identified or identifiable natural person.</p>

        <p>“Processing” means any operation, whether or not automated, that is performed on Personal Data or data sets, e.g., collection, recording, organization, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction.</p>

        <p>Mundo is both a “processor” and “controller” of Personal Data, meaning that we not only collect and use Personal Data, but also, we determine the purposes for which it is processed and the means of processing.</p>

        <p><b>Who is Responsible for your Personal Data?</b><br/>For Personal Data that you submit through our Website (whether by signing up to be on our mailing list or by purchasing goods or services from us) and for Personal Data that is generated when you browse our Website, Mundo bears primary responsibility.</p>

        <p><b>General Purposes for Collection of Personal Data</b><br/>Mundo collects and uses your Personal Data for one or more of the following lawful purposes:</p>

        <ol>
          <li>to provide a range of benefits or services to you;
          <li>to provide necessary information to teacher-trainers and training facilities chosen by you to provide training services;</li>
          <li>to meet our legal or regulatory responsibilities;</li>
          <li>to serve the public interest by providing high quality training and education services in a range of exercise modalities;</li>
          <li>to answer your inquiries;</li>
          <li>to further the interests of Mundo without unduly affecting your interests or fundamental rights and freedoms, which interests may be as diverse as administering and measuring the effectiveness of our advertising and outreach; and increasing our sales of goods and services.</li>
        </ol>

        <p><b>We do not share your Personal Data with any third party.</b></p>

        <p><b>The Personal Data We Collect from You and How We Use It</b><br/>
        Mundo collects information: (1) automatically when you browse our Website, or from (2) web forms that you may fill out, (3) email inquiries and responses, (4) conversations that our representatives have with you via telephone (which information is stored in our CRM. Apart from information collected automatically when you browse our Website, the type of Personal Information we collect from you depends on the nature of your interaction with Mundo. Personal Data may be provided by you electronically on a web form, or via email, fax, or other means.</p>

        <p><b>Retention:</b><br/>Mundo retains indefinitely any Personal Data which you provide us when you submit your personal information on our site. If you want your Personal Data removed from our systems, please contact us your local sales representative.</p>

        <p><b>Information That We Collect Automatically:</b><br/>When you browse our Website, we use various tools to collect information:</p>

        <p><b>Google Analytics:</b> We use Google Analytics, which typically enables us to collect the time of your visit, the pages visited, and time spent on each page or in specific areas of the webpages; referring site details; type of web browser; type of operating system; type of Flash version, JavaScript support, screen resolution, and screen color processing ability; IP address and network location. We may also use the cookies set by Google Analytics to register document downloads, clicks on website links, errors when filling out forms, and scroll depth.</p>

        <p><b>Google Tag Manager:</b> We use Google Tag Manager to analyze whether a user has accessed particular content or completed a valuable action (such as purchasing a product or service).</p>

        <p><b>Facebook Pixel:</b> We may use Facebook Pixel in order to track users who have visited our Websites through advertisements placed by us on Facebook. When users visit our Website as the result of clicking on a Facebook advertisement, Facebook Pixel allow us to track what actions users take on our Website, what links they click on, whether they put something in a shopping cart, where they go if they abandon it, whether they change devices or resume viewing later on a different device, and other website usage information. This information, together with your Facebook ID, is sent back to Facebook where it is analyzed.</p>

        <p><b>Firewall Tracking:</b> Our web application firewall tracks IP addresses for security purposes.</p>

        <p><b>Specific Purposes:</b><br/>Google Analytics, Google Tag Manager, Eloqua Cookies, and Facebook Pixel, and other marketing automation and campaign management tools we may use from time to time, provide us with information that help us provide, maintain, and improve our Website, provide customer support, detect, prevent and address technical issues, and provide you with offers and general information about our goods and Services. The data we collect enable us to measure traffic and usage trends, the effectiveness of our email campaigns and advertising, and use of and interest in particular web pages.</p>

        <p>When used, Facebook Pixel allow us to gauge the effectiveness of our advertisements and to create “lookalike” audiences for our advertisements, thereby broadening our outreach. Facebook Pixel track “conversions,” meaning actions by customers such as a purchase or adding something to a shopping cart.</p>

        <p>Our web application firewall tracks IP addresses for security purposes (e.g., so that we can effectively respond to DDOS attacks), but we do not associate a user’s IP address with any other information that would make the user at that IP address identifiable.</p>

        <p>Personal Data collected by us using Google Analytics are set to expire automatically 90 days after the date on which the Personal Data are collected. You can stop Google Analytics from collecting your Personal Data by disabling cookies or JavaScript in your browser, or by using Google’s Opt-out browser add-on. For more information on Google data collection, please visit the Google Privacy web page at https://policies.google.com/privacy.</p>

        <p>If collected, Personal Data collected as the result of our use of Facebook Pixel will be automatically deleted after 28 days.</p>

        <p>Google Tag Manager is a developer tool used to manage these services. It does not directly result in data to be retained.</p>

        <p><b>Your Data Protection Rights Under General Data Protection Regulation (GDPR)</b><br/>If you are from the European Economic Area (EEA), you have certain data protection rights. We aim to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data. In certain circumstances, you have the following data protection rights, which may be exercised by contacting your local sales represe. Please note, however, that the exercise by you of any of these rights may affect your right or ability to receive information about our products and services, your rights under our product warranties, and the benefits of our services:</p>

        <p>You have the right to access, update or to delete the information we have on you. Please contact your local sales representative to place this request. These rights include:</p>

        <ol>
          <li>The right of rectification. You have the right to have your information rectified if that information is inaccurate or incomplete.</li>
          <li>The right to object. You have the right to object to our processing of your Personal Data.</li>
          <li>The right of restriction. You have the right to request that we restrict the processing of your Personal Data.</li>
          <li>The right to data portability. You have the right to be provided with a copy of the information we have on you in a structured, machine-readable and commonly used format.</li>
          <li>The right to withdraw consent. You also have the right to withdraw your consent at any time where Mundo relied on your consent to process your Personal Data.</li>
          <li>You may have the right to complain to a Data Protection Authority about our collection and use of your Personal Data.</li>
        </ol>
  
        <p>For more information, please contact your local data protection authority in the European Economic Area (EEA).</p>

        <p>Mundo may process your Personal Data because:</p>

        <ol>
          <li>We need to perform a contract with you (e.g., to ship you product that you purchased, or to provide a Service to which you subscribed or for which you enrolled);</li>
          <li>You have given us permission to do so;</li>
          <li>The processing is in our legitimate interests and it’s not overridden by your rights;</li>
          <li>For payment processing purposes; and/or</li>
          <li>To comply with the law.</li>
        </ol>

        <p><b>Transfer of Personal Data</b><br/>Mundo does not share with or sell to any third party any of your Personal Data. However, because we may use cloud services, your information, including Personal Data, may be transferred to and maintained on computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.</p>

        <p>If you are located outside United States and choose to provide information to us, please note that we transfer the data, including Personal Data, to and process it in the United States.</p>

        <p>Your consent to this Privacy Policy followed by your submission of Personal Data to Mundo represents your agreement to that transfer.</p>

        <p>Mundo will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy, and no transfer of your Personal Data will take place to an organization or another country unless there are adequate controls in place including the security of your data and other personal information.</p>

        <p><b>Disclosure of Data Legal Requirements</b><br/>Mundo may disclose your Personal Data in the good faith belief that such action is necessary to:</p>

        <ol>
          <li>To comply with a legal obligation;</li>
          <li>To protect and defend the rights or property of Mundo;</li>
          <li>To prevent or investigate possible wrongdoing in connection with the Service;</li>
          <li>To protect the personal safety of users of the Service or the public;</li>
          <li>To protect against legal liability.</li>
        </ol>

        <p><b>Security of Data</b><br/>The security of your data is important to us but remember that no method of transmission over the internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>

        <p>“Do Not Track” Signals Under California Online Privacy Protection Act (CalOPPA)<br/>DNT is a preference you can set in your web browser to inform websites that you do not want to be tracked. However, there is currently no industry-standard way to handle “Do Not Track” (“DNT”) requests. Consequently, we do not support Do Not Track (“DNT”) browser settings, and our website does not behave differently when a DNT request is received.</p>

        <p><b>Children’s Privacy</b><br/>Our Website and services are not intended for anyone under the age of 18 (“Children”).</p>

        <p>We do not knowingly collect Personal Data from anyone under the age of 18. If you are a parent or guardian and you are aware that your child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.</p>

        <p><b>Changes to This Privacy Policy</b><br/>We may update this Privacy Policy from time to time by posting such update on this web page and specifying the effective date thereof. Changes to this Privacy Policy are effective when they are posted on this web page.</p>
      </template>
      <template v-slot:footer />
    </app-modal>
  </footer>
</template>

<script>
import AppModal from "@/components/AppModal.vue";

  export default {
    name: "AppFooter",

    components: {
      AppModal
    },
  }
</script>

<style scoped>
footer {
  padding: 30px 32px;
  background-color: #fff;
  border: 1px solid #919191;
}

.logo img {
  margin-bottom: 20px;
  width: 186px;
}

.links {
  display: flex;
  justify-content: flex-start;
}

.links a {
  text-decoration: none;
  margin-right: 20px;
  cursor: pointer;
}

.links a:hover,
.links a:active {
  text-decoration: underline;
}

p, ol {
  font-family: Arial, Helvetica, sans-serif;
  margin-bottom: 15px;
  line-height: 1.3em;
}

b {
  font-weight: 700;
}

ol {
  list-style-type: decimal;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

@media screen and (min-width: 640px) {
  footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px 32px;
  }

  .logo img {
    margin-bottom: 0;
    width: 240px;
  }

  .links a {
    margin-right: 0;
    margin-left: 20px;
  }
}
</style>