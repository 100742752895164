<template>
  <div
    class="zoom-on-hover"
    v-bind:class="{zoomed}"
    @mousemove="move"
    @mouseleave="unzoom"
    @click="zoom"
  >
    <img class="normal" ref="normal" :src="imgNormal"/>
    <img class="zoom" ref="zoom" :src="imgZoom || imgNormal"/>
    <img src="@/assets/images/icon-zoom-in.svg" ref="in" class="icon" alt="Zoom in">
    <img src="@/assets/images/icon-zoom-out.svg" ref="out" class="icon" alt="Zoom out">
  </div>
</template>

<script>
function pageOffset(el) {
  // -> {x: number, y: number}
  // get the left and top offset of a dom block element
  var rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return {
    y: rect.top + scrollTop,
    x: rect.left + scrollLeft
  }
}

export default {
  name: "ZoomOnHover",

  props: [
    "imgNormal",
    "imgZoom",
    "scale",
    "disabled"
  ],

  data() {
    return {
      scaleFactor: 1,
      resizeCheckInterval: null,
      zoomed: false
    }
  },

  mounted() {
    if (this.$props.scale) {
      this.scaleFactor = parseInt(this.$props.scale)
      this.$refs.zoom.style.transform = "scale(" + this.scaleFactor + ")"
      this.$refs.in.style.display = "block";
      this.$refs.out.style.display = "none";
    }
    this.initEventLoaded()
    this.initEventResized()
  },

  updated() {
    this.initEventLoaded()
  },
  
  beforeDestroy() {
    this.resizeCheckInterval && clearInterval(this.resizeCheckInterval)
  },

  methods: {
    zoom() {
      if (!this.disabled) this.zoomed = !this.zoomed
      if (this.zoomed === true) {
        this.$refs.in.style.display = "none";
        this.$refs.out.style.display = "block";
        this.move(event);
      }
      if (this.zoomed === false) {
        this.$refs.in.style.display = "block";
        this.$refs.out.style.display = "none";
      }
    },

    unzoom() {
      if (!this.disabled) this.zoomed = false
      this.$refs.in.style.display = "block";
      this.$refs.out.style.display = "none";
    },

    move(event) {
      if (this.disabled || !this.zoomed) return
      var offset = pageOffset(this.$el)
      var zoom = this.$refs.zoom
      var normal = this.$refs.normal
      var relativeX = event.clientX - offset.x + window.pageXOffset
      var relativeY = event.clientY - offset.y + window.pageYOffset
      var normalFactorX = relativeX / normal.offsetWidth
      var normalFactorY = relativeY / normal.offsetHeight
      var x = normalFactorX * (zoom.offsetWidth * this.scaleFactor - normal.offsetWidth)
      var y = normalFactorY * (zoom.offsetHeight * this.scaleFactor - normal.offsetHeight)
      zoom.style.left = -x + "px"
      zoom.style.top = -y + "px"
    },

    initEventLoaded() {
      // emit the "loaded" event if all images have been loaded
      var promises = [this.$refs.zoom, this.$refs.normal].map(function(image) {
        return new Promise(function(resolve, reject) {
          image.addEventListener("load", resolve)
          image.addEventListener("error", reject)
        })
      })
      var component = this
      Promise.all(promises).then(function() {
        component.$emit("loaded")
      })
    },

    initEventResized() {
      var normal = this.$refs.normal
      var previousWidth = normal.offsetWidth
      var previousHeight = normal.offsetHeight
      var component = this
      this.resizeCheckInterval = setInterval(function() {
        if ((previousWidth != normal.offsetWidth) || (previousHeight != normal.offsetHeight)) {
          previousWidth = normal.offsetWidth
          previousHeight = normal.offsetHeight
          component.$emit("resized", {
            width: normal.width,
            height: normal.height,
            fullWidth: normal.naturalWidth,
            fullHeight: normal.naturalHeight
          })
        }
      }, 1000)
    }
  }
}
</script>

<style scoped>
.zoom-on-hover {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.zoom-on-hover .normal {
  width: 100%;
}
.zoom-on-hover .zoom {
  position: absolute;
  opacity: 0;
  transform-origin: top left;
}
.zoom-on-hover.zoomed .zoom {
  opacity: 1;
}
.zoom-on-hover.zoomed .normal {
  opacity: 0;
}
.icon {
  position: absolute;
  bottom: 5px;
  right: 5px;
  z-index: 10;
  width: 40px
}
@media screen and (max-width: 640px) {
  .icon {
    display: none !important;
  }
}
</style>
