<template>
  <header>
    <a href="/" class="link">
      <img src="@/assets/images/logo-mundo-h.svg" alt="Mundo by Balanced Body logo">
    </a>
    <btn-primary-alt @open-modal="$emit('open-modal')">Contact Us</btn-primary-alt>
  </header>
</template>

<script>
import BtnPrimaryAlt from "@/components/BtnPrimaryAlt.vue";

export default {
  name: "AppHeader",

  components: {
    BtnPrimaryAlt
  },
}
</script>

<style scoped>
header {
  padding: 24px 24px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
}

img {
  width: 186px;
}

::v-deep .btn {
  padding: 12px 10px;
}

@media screen and (min-width: 640px) {
  header {
    padding: 30px 42px 20px;
    display: block;
    position: relative;
  }

  .link {
    margin: 0 auto;
    display: block;
    width: 240px;
  }

  img {
    width: 240px;
  }

  .btn-primary {
    position: absolute;
    top: 38px;
    right: 42px;
  }

  ::v-deep .btn {
    padding: 15px 30px;
  }
}
</style>