import axios from 'axios';

const apiClient = axios.create({
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
});

export default {
  getCountryCode() {
    const baseUrl = process.env.VUE_APP_BB_GEOIP_API;
    return apiClient.get(baseUrl);
  },

  getCountryList() {
    const baseUrl = process.env.VUE_APP_BB_COUNTRIES_API;
    return apiClient.get(baseUrl);
  },

  postForm(obj) {
    const baseUrl = process.env.VUE_APP_BB_WEBLEADS_API;
    return apiClient.post(baseUrl, obj);
  },
};
