<template>
  <section class="features">
    <img
      :src="`${s3}mundo-featuredproduct-mobile@1x.png`"
      :srcset="`${s3}mundo-featuredproduct-mobile@2x.png 2x`"
      alt="Reformer product photo"
      class="hero narrow"
    />

    <img
      :src="`${s3}mundo-featuredproduct-desk@1x.png`"
      :srcset="`${s3}mundo-featuredproduct-desk@2x.png 2x`"
      alt="Reformer product photo"
      class="hero2 wide"
    />

    <div class="bg">
      <article>
        <img src="@/assets/images/icon-craftsmanship.svg" alt="Craftsmanship llustration" />
        <div class="content">
          <p class="h4">Quality Craftsmanship</p>
          <p class="p3">Hand-built by experienced craftsmen in a socially-responsible manufacturing facility.</p>
        </div>
      </article>

      <article>
        <img src="@/assets/images/icon-wood.svg" alt="Illustration of tree" />
        <div class="content">
          <p class="h4">Sustainably-Grown Wood</p>
          <p class="p3">Verde lyptus is a hybrid Eucalyptus wood, grown on plantations as a beautiful, durable and sustainable resource.</p>
        </div>
      </article>

      <article>
        <img src="@/assets/images/icon-components.svg" alt="Components Illustration" />
        <div class="content">
          <p class="h4">Balanced Body® Signature Components</p>
          <p class="p3">Mundo equipment features Balanced Body Signature Springs™ and wheels.</p>
        </div>
      </article>
    </div>
  </section>
</template>

<script>
  export default {
    name: "Features",

    props: {
      s3: String
    },
  }
</script>

<style scoped>
.hero {
  display: block;
}

.wide {
  display: none;
}

.features {
  overflow: hidden;
}

.hero {
  width: 120%;
  max-width: 910px;
}

.bg {
  background-color: var(--earth-dk);
  padding: 180px 32px 25px;
  margin-top: -145px;
}

article {
  display: flex;
  align-items: flex-start;
  margin: 0 auto 50px;
  width: 100%;
  max-width: 310px;
}

.content {
  margin-left: 16px;
}

.h4 {
  color: var(--olive-lt);
  margin-bottom: 4px;
}

.p3 {
  color: var(--gray1);
}

@media screen and (min-width: 500px) {
  .bg {
    margin-top: -200px;
  }
}

@media screen and (min-width: 575px) {
  .bg {
    margin-top: -185px;
  }

  .hero {
    width: 100%;
    max-width: none;
  }
}

@media screen and (min-width: 727px) {
  .bg {
    margin-top: -220px;
  }
}

@media screen and (min-width: 940px) {
  .narrow {
    display: none;
  }

  .wide {
    display: block;
  }

  .features {
    position: relative;
    overflow: visible;
    background-color: var(--earth-pale);
    padding-bottom: 130px;
  }

  .bg {
    padding: 40px 0 0;
    margin-top: 0;
    height: 433px;;
  }

  article {
    margin: 0 0 30px 30%;
  }

  .hero2 {
    position: absolute;
    top: -100px;
    left: 50%;
    transform: translateX(-60%);
    width: 975px;
  }
}
</style>
