<template>
  <div class="product-wrapper" :class="product.bg" :id="product.imgName">
    <div class="wide">
      <slot name="photos"/>
    </div>
    <div class="content">
      <header>
        <div class="p1">Mundo</div>
        <p class="h1">{{ product.name }}</p>
        <div class="border"></div>
      </header>
      <div class="narrow">
        <slot name="photos"/>
      </div>
      <slot name="description"/>
      <slot name="accessories"/>

      <!-- Patent information -->
      <p class="p2 patent-info">
        <a class="patent-accessory-link" href="https://www.pilates.com/company/balanced-body-patent-data">Click here</a> for <span>Balanced Body Patent Data</span>.
      </p>

      <!-- Product Warranty -->
      <p class="p2 patent-info">
        <a class="patent-accessory-link" href="https://s3.amazonaws.com/s3.pilates.com/resources/6-15-21_BB-Mundo_Warranty_FINAL.pdf" target="_blank">Click here</a> for <span>Product Warranty</span>.
      </p>

      <slot name="accordion"/>
      <btn-primary @open-modal="$emit('open-modal')">Contact Us For Info</btn-primary>
      <btn-link :url="product.manualUrl" class="link">Download Product Manual</btn-link>
    </div>
  </div>
</template>

<script>
import BtnPrimary from "@/components/BtnPrimary.vue";
import BtnLink from "@/components/BtnLink.vue";

export default {
  name: "ProductWrapper",

  components: {
    BtnPrimary,
    BtnLink
  },

  props: {
    product: Object
  },
}
</script>

<style scoped>
.narrow {
  display: block;
}

.wide {
  display: none;
}

.product-wrapper {
  padding: 56px 32px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.p1 {
  color: var(--gray5);
}

.border {
  width: 56px;
  height: 6px;
  background-color: var(--gray5);
  margin: 15px 0 25px;
}

.link {
  margin-top: 10px;
}

.patent-info {
  margin-top: 13rem;
}

.patent-info a {
    color: var(--link-dk);
}

.patent-info span {
  font-weight: 900; 
  border: none;
}

@media only screen and (min-width: 940px) {
  .narrow {
    display: none;
  }

  .wide {
    display: block;
  }

  .product-wrapper {
    max-width: 900px;
    padding: 96px 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  
  .content {
    flex: 0 0 465px;
  }
}
</style>
