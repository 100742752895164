<template>
  <div @click="setFeature">
    <img
      ref="active"
      :src="`${s3}mundo-${prodImgName}-${photoIndex}@1x.jpg`"
      alt="Product photo"
    />
  </div>
</template>

<script>
export default {
  name: "Photo",

  props: {
    s3: {
      type: String
    },
    prodImgName: {
      type: String
    },
    photoIndex: {
      type: Number
    },
    prodIndex: {
      type: Number
    },
  },

  methods: {
    setFeature() {
      this.$emit('setFeature', { photoIndex: this.photoIndex, prodIndex: this.prodIndex })
      this.$refs.active.classList.value = this.$refs.active.classList.value + "active";
    }
  }
}
</script>

<style scoped>
.photo-grid img {
  width: 100%;
  display: block;
  cursor: pointer;
  border: 2px solid transparent;
}

.photo-grid img:hover,
.photo-grid img:active {
  border: 2px solid var(--earth-md);
}

.photo-grid img.active {
  border: 2px solid var(--earth-md);
}
</style>
