import Vue from 'vue'
import App from './App.vue'
import VueCookies from 'vue-cookies';

Vue.use(VueCookies);

require('@/assets/styles/resets.css');
require('@/assets/styles/base.css');

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
