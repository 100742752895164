<template>
  <div id="app">
    <div class="loading" v-if="loading" >
      <img src="https://s3.amazonaws.com/s3.pilates.com/img/mundo-loader.gif" alt="Stay tuned"/>
    </div>

    <div v-if="isdistcountry">
      <app-header @open-modal="$refs.contact.openModal()"/>
      <app-video :s3="s3" />
      <intro :s3="s3" />
      <features :s3="s3" />
      <section class="product-bg" v-for="(product, prodIndex) in products" :key="prodIndex">
        <product-wrapper :product="product" @open-modal="$refs.contact.openModal()">
          <template #photos>
            <photo-grid
              :s3="s3"
              :prodIndex="prodIndex"
              :prodImgName="product.imgName"
              :feature="featureImgArr"
            >
              <photo
                v-for="(photoIndex, index) in product.imgNumber"
                :key=index
                @setFeature="setSelectedImg"
                :prodImgName="product.imgName"
                :s3="s3"
                :photoIndex="photoIndex"
                :prodIndex="prodIndex"
              />
            </photo-grid>
          </template>
          <template #description>
            <p class="p2" v-html="product.description"/>
          </template>
          <template #accessories>
            <accessories-links
              v-if="product.accessories.length > 0"
              :accessories="product.accessories"
            />
          </template>
          <template #accordion>
            <div class="accordion-wrapper">
              <div
                v-for="(accordion, index) in product.accordions"
                :key="index"
              >
                <product-accordion>
                  <template #title>{{ accordion.title }}</template>
                  <template #content>
                    <ul v-for="(item, index) in accordion.text" :key="index">
                      <li class="p2">{{ item }}</li>
                    </ul>
                  </template>
                </product-accordion>
              </div>
            </div>
          </template>
        </product-wrapper>
      </section>
      <accessories-wrapper>
        <template #accessories>
          <div class="accessories">
            <div
              v-for="(accessory, index) in accessories"
              :key="index"
            >
              <accessory-accordion
                :imgName="accessory.imgName"
                :imgNumber="accessory.imgNumber"
                :url="accessory.manualUrl"
                :s3="s3"
                @open-modal="$refs.contact.openModal()"
              >
                <template #title>{{ accessory.title }}</template>
                <template #link><a :href="accessory.hyperlink">{{ accessory.hypertext }}</a></template>
                <template #content>
                  <p class="p2" v-html="accessory.content"/>
                </template>
              </accessory-accordion>
            </div>
          </div>
        </template>
      </accessories-wrapper>
      <outro :s3="s3" />
      <app-footer @open-modal="$refs.contact.openModal()"/>
      <app-modal ref="contact">
        <template v-slot:header />
        <template v-slot:body>
          <contact-us/>
        </template>
        <template v-slot:footer />
      </app-modal>
    </div>

    <div v-if="!isdistcountry" class="msg-center">
      <div class="msg-flex">
        <img src="@/assets/images/logo-mundo-h.svg" alt="Mundo by Balanced Body logo"/>
        <h1 class="h1" style="margin-top: 30px;">Mundo equipment is not currently available in your country.</h1>
        <p class="p1" style="margin-top: 20px;">Please <a href="mailto:info@pilates.com">contact us</a> for more information.</p>
      </div>
      <img
        :src="`${s3}mundo-outsidecountry@1x.jpg`"
        :srcset="`${s3}mundo-outsidecountry@2x.jpg 2x, ${s3}mundo-outsidecountry@3x.jpg 3x`"
        alt="Mundo by Balanced Body logo"
        class="product-photo"
      />
    </div>
    <app-modal ref="contact2">
      <template v-slot:header />
      <template v-slot:body>
        <contact-us/>
      </template>
      <template v-slot:footer />
    </app-modal>
  </div>
</template>

<script>
import ApiService from "@/js/ApiService.js";
import AppHeader from "@/components/AppHeader.vue";
import AppVideo from "@/components/AppVideo.vue";
import Intro from "@/components/Intro.vue";
import Features from "@/components/Features.vue";
import ProductWrapper from "@/components/ProductWrapper.vue";
import PhotoGrid from "@/components/PhotoGrid.vue";
import Photo from "@/components/Photo.vue";
import AccessoriesLinks from "@/components/AccessoriesLinks.vue";
import ProductAccordion from "@/components/ProductAccordion.vue";
import AccessoriesWrapper from "@/components/AccessoriesWrapper.vue";
import AccessoryAccordion from "@/components/AccessoryAccordion.vue";
import Outro from "@/components/Outro.vue";
import AppModal from "@/components/AppModal.vue";
import ContactUs from "@/components/ContactUs.vue";
import AppFooter from "@/components/AppFooter.vue";

export default {
  name: 'App',

  cookieName: 'countrycode',

  components: {
    AppHeader,
    AppVideo,
    Intro,
    Features,
    ProductWrapper,
    PhotoGrid,
    Photo,
    AccessoriesLinks,
    ProductAccordion,
    AccessoriesWrapper,
    AccessoryAccordion,
    Outro,
    AppModal,
    ContactUs,
    AppFooter,
  },

  data() {
    return {
      loading: false,
      countrycode: "",
      defaultcountry: "world",
      s3: "https://s3.amazonaws.com/s3.pilates.com/img/landing-pages/mundo/",
      featureImgArr: [], // PhotoGrid
      products: [
        {
          name: "Reformer",
          description: "The Mundo Reformer is a strong, stable machine that provides a long-lasting quality Pilates exercise experience. Sleek, clean lines are hand-finished to appeal to the eye and touch. With a smooth, quiet carriage ride, the Mundo Reformer lives up to expectations for this iconic piece of Pilates equipment.",
          imgName: "reformer", //ex: mundo-reformer-1@1x.jpg
          imgNumber: 4,
          manualUrl: "https://s3.amazonaws.com/s3.pilates.com/resources/17715_Studio_Reformer_3-23-21.pdf",
          accessories: ["Jumpboard"],
          accordions: [
            {
              title: "Features",
              text: [
                "Handcrafted solid Verde Lyptus wood frame with one piece rail structure for smooth carriage ride and greater frame stability",
                "Heavy-duty hardware and visible aluminum parts anodized to reduce friction, prevent oxidation and eliminate ″black dust″",
                "Wood risers allow for a quick height adjustment, while swivel pulleys allow for variable angles of resistance and ropes can be pulled easily in any direction",
                "REVO Footbar with round 2-position Springbar",
                "Nautical ropes are secured by nautical cam cleats. Rope length adjustments are quick and easy",
                "Padded shoulder rests are sculpted for comfort, with posts that hold ropes off the floor",
                "Balanced Body® Signature Springs",
                "Lightweight yet strong, the padded, upholstered carriage offers a comfortable, responsive and stable surface",
                "Balanced Body® 8-wheel system with 4 custom-built vertical wheels that support the carriage, and 4 adjustable side wheels to guide the carriage",
                "3 range-limiting carriage stops control the extension of the carriage",
                "3-position padded headrest for cervical support",
                "A cut-out at the head of the Reformer adds an extra 8.9cm (3.5″) of carriage travel",
                "Built-in standing platform is 69cm x 13cm (27″ x 5.12″), with textured grip surface",
                "Integrated Jumpboard bracket",
                "Upholstery: Black",
              ]
            },
            // {
            //   title: "Carriage, Straps and Handles",
            //   text: [
            //     "Carriages are lightweight yet strong, offering a responsive and stable surface",
            //     "Precision carriage system rides on one-piece rail structure that provides smooth carriage ride and greater frame stability",
            //     "Carriage tracking system creates an ultra-smooth, virtually silent ride. Our 8-wheel system features 4 custom-built vertical wheels to support the carriage, and 4 adjustable side wheels to guide the carriage smoothly down the track", 
            //     "Control the extension of the carriage with 3 range-limiting carriage stops at head of the frame",
            //     "Maximum carriage travel – advantageous for jumping exercises-  40.15”/ 102cm",
            //   ]
            // },
            {
              title: "Includes",
              text: [
                "Balanced Body Sitting Box Lite",
                "Balanced Body Signature Springs™ (5 springs – 3 red, 1 blue, 1 green)",
                "1 pair Neoprene-padded handles",
                "1 pair padded single loops",
                "1 padded footstrap",
              ]
            },
            {
              title: "Specifications",
              text: [
                "Height: 36cm / 14”",
                "Length: 237cm / 93.3”", 
                "Width: 67.5cm / 26.57”",
                "Carriage pad (pad only, not including head rest): Length: 78.5cm / 30.9”, Width: 60cm / 23.6”",
              ]
            }
          ]
        },
        {
          name: "Reformer with Tower",
          description: "Reformer, Mat and Tower, all in one. Work in multiple planes of resistance, on the Mat or moving platform, and from both sides of the Tower. The Mundo space-saving Reformer with Tower offers flexibility and new programming options.<br/><br/>To convert from Reformer to Tower, one lightweight mat fits into the Reformer frame at the same level of the carriage. No need to lift, flip or turn the carriage. Stainless steel Tower is engineered for stability and safety, and designed to disperse stress throughout the frame while in use.",
          imgName: "reformertower", //ex: mundo-reformertower-1@1x.jpg
          imgNumber: 4,
          manualUrl: "https://s3.amazonaws.com/s3.pilates.com/resources/Mundo_Reformer_with_Tower_assembly_instructions.pdf",
          accessories: ["Jumpboard"],
          accordions: [
            {
              title: "Features",
              text: [
                "Tower offers multiple attachment points for increased exercise options",
                "Adjustable Aluminum 4-side Push-Through Bar (PTB)",
                "PTB slide system is fast and easy, helping maintain the flow of exercises",
                "Padded Twistlock™ shoulder rests are sculpted for comfort and provide quick adjustment to 2.5cm (1”) wider spacing or easy twist-off removal",
                "Installation of Mat Conversion is simple: twist off shoulder rests, put aside and drop in 1 piece Mat.",
                "Includes all Mundo Reformer features and benefits",
              ]
            },
            {
              title: "Includes",
              text: [
                "6 Balanced Body Signature Springs™ (2 short blue, 2 long yellow, 2 long purple) for Tower (in addition to 5 Balanced Body Signature Springs™ for Reformer)",
                "1 pair adjustable, padded thigh cuffs",
                "1 pair padded ankle cuffs",
              ]
            },
            {
              title: "Specifications",
              text: [
                "Reformer height, including carriage: 55cm / 21.65”",
                "Reformer length with Tower brackets: 242.5cm / 95.5”",
                "Reformer width with Tower brackets: 70.2cm / 27.6”",
                "(Tower width, including push-through bar adjustment knobs): 78.5cm / 30.9”",
                "Tower height: 188cm / 74”",
                "Weight of Studio Reformer with Tower: 90kg / 198.4lbs",
              ]
            }
          ]
        },
        {
          name: "Cadillac",
          description: "Engineered for stability, efficient adjustment and safety in use, our Mundo Cadillac supports fitness and rehabilitation with a broad spectrum of exercises from gentle core movement to advanced acrobatics.<br/><br/>Our Cadillac features sustainably-grown hardwood in a beautiful handcrafted base, and smooth stainless steel in the frame and canopy. The sliding cross bar system includes self-lubricating bushings and butterfly screws that promote smooth slider movement. Padded and upholstered benchtop offers comfort and cushioning during movement.",
          imgName: "cadillac", //ex: mundo-cadillac-1@1x.jpg
          imgNumber: 4,
          manualUrl: "https://s3.amazonaws.com/s3.pilates.com/resources/17717_Trapeze_Table_3-23-21.pdf",
          accessories: [],
          accordions: [
            {
              title: "Features",
              text: [
                "Table Base is 7.5cm (3″) wider than traditional Cadillacs to accommodate a broad range of body types",
                "Solid wood Roll-Down Bar is 80cm (31.5″) long with inside hooks",
                "Padded Trapeze Bar with padded spring sleeves and cotton canopy loop (long web, black)",
                "Horizontal and vertical Slider Bars offer numerous positions for spring attachments. Butterfly knobs allow easy and secure adjustments",
                "Push-Through Bar (PTB) Slide System is fast and easy, promoting flow in session",
                "Constructed of anodized aluminum, the PTB adjusts up to 4 heights and can be assembled as 3-sided or 4-sided bar (safety strap and carabiner included)",
                "Heavy-duty vinyl upholstery throughout with “mud flaps” to protect the tabletop mat edges from bottom-spring springs",
              ]
            },
            {
              title: "Includes",
              text: [
                "1 pair each: single D-ring loops, adjustable thigh cuffs and adjustable ankle cuffs",
                "1 pair lambswool fuzzies with long cotton webs",
                "1 Belly strap of black, cotton webbing",
                "1 pair Neoprene handles",
                "Balanced Body Signature Springs™ with snap hooks",
                "Trapeze springs: 2 blue (light) and 2 black (extra heavy)",
                "Long springs: 2 yellow (very light) and 2 purple (medium)",
              ]
            },
            {
              title: "Specifications",
              text: [
                "Table surface height, including upholstery: 66cm / 26”",
                "Table width: 74cm / 29.13”",
                "Width, including push-through bar adjustment knobs: 78cm / 30.71”",
                "Table length: 217cm / 85.43”, plus 11cm / 4.33” for canopy brackets",
                "Trapeze frame height (from top to floor): 220cm / 86.61”",
                "Weight: 102kg / 224.87lbs",
              ]
            }
          ]
        },
        {
          name: "Step Chair",
          description: "The ultimate space-saving equipment, the Pilates Chair is designed to stretch and strengthen targeted muscle groups. Evolving from Joseph Pilates’ original Wunda Chair, the Mundo Step Chair features a padded split pedal design for rotational and reciprocal movement of the extremities. Each pedal has 2 springs, offering a wide range of resistance.<br/><br/>The Mundo Step Chair is built for strength and stability. Developed to work with the Mundo Cadillac, the Step Chair is ideal to use at the end of the Trap Table. The chair handles feature traditional square tubing and are easily removed as needed.",
          imgName: "chair", //ex: mundo-chair-1@1x.jpg
          imgNumber: 4,
          manualUrl: "https://s3.amazonaws.com/s3.pilates.com/resources/17718_Step_Chair_3-23-21.pdf",
          accessories: [],
          accordions: [
            {
              title: "Features",
              text: [
                "Padded, upholstered seat for comfort and cushioning",
                "Split Pedal easily converts to Single Pedal with dowel (included)",
                "Pedals are comfort-padded with non-slip textured grip",
                "Exclusive cactus attachment system makes spring changes simple, fast and safe. 4 spring attachment positions",
                "Adjustable and removable stainless steel handles",
                "Upholstery: Black",
              ]
            },
            {
              title: "Includes",
              text: [
                "4 Balanced Body® Signature Springs™ - 2 Black and 2 White",
                "Dowel (To convert Spilt Pedal to Single Pedal)",
              ]
            },
            {
              title: "Specifications",
              text: [
                "Height: 66cm / 26”",
                "Length: 78cm / 30.7”",
                "Width, including handles and knobs: 71.5cm / 28.15”",
                "Seat: 30.5 X 55cm / 12 X 21.65”",
                "Weight: 36kg / 79.4lbs",
              ]
            }
          ]
        },
        {
          name: "Ladder Barrel",
          description: "Complete your Mundo Equipment Suite with our Ladder Barrel, an essential piece in building core strength and flexibility. Upholstered in durable vinyl, the Barrel features double foam padding for extra comfort and cushioning doing prone exercises like Swan, and side lying exercises like Side Sit Up and Mermaid.<br/><br/>Built of sustainably-grown hardwood, the Ladder connects to the Barrel by an adjustable sliding base to accommodate different torso and leg lengths. Adjusting distance between Ladder and Barrel is easy -- just release the knobs, slide the Barrel to the position you want and fasten the knobs.",
          imgName: "barrel", //ex: mundo-barrel-1@1x.jpg
          imgNumber: 4,
          manualUrl: "https://s3.amazonaws.com/s3.pilates.com/resources/17713_Ladder_Barrel_3-23-21.pdf",
          accessories: [],
          accordions: [
            {
              title: "Features",
              text: [
                "Durable, beautiful reinforced frame of solid sustainably grown hardwood",
                "Sliding base with up to 25cm (10”) of adjustability",
                "“Comfort zone” padding for double-layer cushioning, stability, and support, on the Barrel",
                "Grab bar beneath the barrel enhances exercise options",
                "Upholstery: Black",
              ]
            },
            {
              title: "Specifications",
              text: [
                "Ladder height: 97cm / 38.19”",
                "Barrel height: 89cm / 35.04”",
                "Length: 120cm / 47.24”",
                "Width: 64cm / 25.19”",
                "Weight: 32kg / 70.55lbs",
              ]
            }
          ]
        }
      ],
      accessories: [
        {
          title: "Padded Jumpboard",
          content: "The Mundo Padded Jumpboard adds great versatility to your Reformer. It provides jumping and other cardio exercises, and recreates a biomechanically accurate standing position while lying on the Reformer. Upholstered in the same non-skid upholstery as the footbar cover, the Jumpboard features dense, durable padding.<br/><br/>Upholstery: Black<br/>Dimensions of padded board: Height 52.5cm / 20.67”; Width: 68cm / 26.77”<br/>Weight: 5kg / 11lbs",
          hyperlink: "#reformer",
          hypertext: "For Mundo Reformer",
          imgName: "jumpboard", //ex: mundo-jumpboard-1@1x.jpg
          imgNumber: 3,
          manualUrl: "https://s3.amazonaws.com/s3.pilates.com/resources/17714_Jumpboard_3-23-21.pdf",
        }
      ]
    }
  },

  computed: {
    isdistcountry() {
      return this.distcountry[this.countrycode] ? true : false;
    },

    distcountry() {
      return JSON.parse(process.env.VUE_APP_BB_DISTRIBUTORS);
    }
  },

  created() {
    this.checkLocation();
  },

  mounted() {
    this.setInitImg();
  },

  methods: {
    async checkLocation() {
      this.loading = true;
      this.getGeoCookie(this.$options.cookieName)
      if (!this.countrycode) {
        await ApiService.getCountryCode()
        .then(response => {
          if (response.data.haserror === true) {
            this.countrycode = 'world';
            this.setGeoCookie(this.$options.cookieName, 'world', '1d');
          } else {
            this.countrycode = response.data.country_iso.toLowerCase();
            this.setGeoCookie(this.$options.cookieName, this.countrycode, '1d');
          }
          this.loading = false;
        })
        .catch(() => {
          this.setGeoCookie(this.$options.cookieName, this.defaultcountry, '1d');
          this.loading = false;
        })
      } else {
        this.loading = false;
      }
    },

    setGeoCookie(cname, cvalue, expire) {
      return this.$cookies.set(cname, cvalue, expire);
    },

    getGeoCookie(cname) {
      const cookieVal = this.$cookies.get(cname);
      return ( cookieVal ? this.countrycode = cookieVal : this.countrycode = '');
    },

    setInitImg() {
      for (let i = 0; i < this.products.length; i++) {
        this.featureImgArr.push(1);
      }
    },

    setSelectedImg(obj) {
      let a = document.getElementsByClassName( "active" );
      [...a].forEach( x => x.classList.remove("active") );
      this.$set(this.featureImgArr, obj.prodIndex, obj.photoIndex)
    }
  }
}
</script>

<style>
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

#app {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}

.product-bg:nth-of-type(even) {
  background-color: var(--earth-pale);
}

.product-bg:nth-of-type(odd) {
  background-color: var(--white);
}

.accordion-wrapper {
  margin-bottom: 30px;
  margin-top: 30px;
}

.msg-center {
  padding: 32px;
  display: flex;
  flex-flow: column nowrap;
}

.product-photo {
  width: 100%;
  max-width: 450px;
  display: block;
  margin: 30px auto 0;
}

@media screen and (min-width: 830px) {
  .msg-center {
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .msg-flex {
    order: 2;
    margin-left: 4vw;
    max-width: 470px;
  }

  .product-photo {
    order: 1;
    max-width: 450px;
    margin: 0;
  }
}
</style>
