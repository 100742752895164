<template>
  <div class="wrapper">
    <div class="accordion" @click="toggle">
      <div class="h1">
        <slot name="title" />
      </div>
      <div class="link">
        <slot name="link" />
      </div>
      <div class="border" />
      <img src="@/assets/images/chevron-down.svg" alt="Expand" v-show="show === false">
      <img src="@/assets/images/chevron-up.svg" alt="Collapse" v-show="show === true">
    </div>
    <div v-show="show" class="content">
      <vueper-slides
        :bullets="false"
        fixed-height
      >
      <template v-slot:arrow-left>
        <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0)"><path d="M20.5.25C9.316.25.25 9.316.25 20.5S9.316 40.75 20.5 40.75s20.25-9.066 20.25-20.25S31.684.25 20.5.25z" fill="#242424" fill-opacity=".5"/><path d="M23.071 12.4l-8.1 8.1 8.1 8.1" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="clip0"><path fill="#fff" transform="rotate(180 20.5 20.5)" d="M0 0h41v41H0z"/></clipPath></defs></svg>
      </template>
      <template v-slot:arrow-right>
        <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.5 40.75c11.184 0 20.25-9.066 20.25-20.25S31.684.25 20.5.25.25 9.316.25 20.5 9.316 40.75 20.5 40.75z" fill="#242424" fill-opacity=".5"/><path d="M17.929 28.6l8.1-8.1-8.1-8.1" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
      </template>
        <vueper-slide
          v-for="(img, i) in imgArr"
          :key="i"
          :content="`<img src='${s3}mundo-${imgName}-${img}@1x.jpg' srcset='${s3}mundo-${imgName}-${img}@2x.jpg 2x' alt='Product photo'>`"
        />
      </vueper-slides>
      <slot name="content" />

      <!-- Patent information -->
      <p class="p2 patent-info">
        <a class="patent-accessory-link" href="https://www.pilates.com/company/balanced-body-patent-data">Click here</a> for <span>Balanced Body Patent Data</span>.
      </p>

      <!-- Product Warranty -->
      <p class="p2 patent-info">
        <a class="patent-accessory-link" href="https://s3.amazonaws.com/s3.pilates.com/resources/6-15-21_BB-Mundo_Warranty_FINAL.pdf" target="_blank">Click here</a> for <span>Product Warranty</span>.
      </p>

      <btn-primary @open-modal="$emit('open-modal')">Contact Us For Info</btn-primary>
      <btn-link :url="url" class="btn-link">Download Product Manual</btn-link>
    </div>
  </div>
</template> 

<script>
import BtnPrimary from "@/components/BtnPrimary.vue";
import BtnLink from "@/components/BtnLink.vue";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";

  export default {
    name: "AccessoryAccordion",

    components: {
      BtnPrimary,
      BtnLink,
      VueperSlides,
      VueperSlide,
    },

    props: {
      s3: String,
      imgName: String,
      imgNumber: Number,
      url: String
    },

    data() {
      return {
        show: false,
        imgArr: [], // [0, 1, 2]
        slides: [
          {
            title: '<p class="p2">Slide #1</p>',
            content: '<p class="p2">Slide content.</p>'
          },
          {
            title: '<p class="p2">Slide #2</p>',
            content: '<p class="p2">More Slide content.</p>'
          },
        ]
      };
    },

    mounted() {
      this.initImgArr();
    },

    methods: {
      toggle() {
        this.show = !this.show;
      },

      initImgArr() {
        for (let i = 1; i <= this.imgNumber; i++) {
          this.imgArr.push(i);
        }
      }
    }
  }
</script>

<style scoped>
.wrapper {
  border: 1px solid var(--gray2);
  border-radius: 15px;
  padding: 24px 36px;
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
}

.accordion {
  position: relative;
  cursor: pointer;
}

.h1 {
  color: var(--white);
}

.p2 {
  color: var(--white);
  margin-top: 30px;
}

.patent-info {
  margin-top: 13rem;
}

.patent-info a {
    color: var(--link-lt);
}

.patent-info span {
  font-weight: 900; 
  border: none;
}

.link a {
  font-family: var(--mont1);
  font-style: normal;
  font-weight: 500;
  font-size: 15rem;
  line-height: 1.47;
  text-decoration: underline;
  text-transform: capitalize;
  color: var(--link-lt);
}

.link a:hover,
.patent-info .patent-accessory-link:hover {
  color: var(--white);
}

.border {
  width: 56px;
  height: 6px;
  background: var(--olive-lt);
  margin-top: 20px;
}

.accordion img {
  position: absolute;
  right: 0;
  top: 45%;
}

::v-deep .btn {
  border: 1px solid var(--white);
  margin-top: 30px;
  margin-bottom: 20px;
}

::v-deep .vueperslides__arrow--prev {
  left: -30px;
}

::v-deep .vueperslides__arrow--next {
  right: -30px;
}

::v-deep svg {
  width: 40px;
}

::v-deep .vueperslide__content img {
  width: 100%;
  object-fit: cover;
}

::v-deep .vueperslides--fixed-height { 
  height: min(41vw, 232px);
}

::v-deep .vueperslides {
  margin-top: 40px;
}

.btn-link {
  margin-top: -40px;
}
</style>
