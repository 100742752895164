<template>
  <section class="accessories-wrapper" id="accessories">
    <div class="narrow">
      <div class="inner">
        <h3 class="h3">Mundo Accessories</h3>
        <divider type="right" />
      </div>
      <slot name="accessories" />
    </div>
    
    <div class="wide">
      <div class="inner">
        <divider type="left" />
        <h3 class="h3">Mundo Accessories</h3>
        <divider type="right" />
      </div>
      <slot name="accessories" />
    </div>
  </section>
</template>

<script>
import Divider from "@/components/Divider.vue";

  export default {
    name: "AccessoriesWrapper",

    components: {
      Divider,
    },
  }
</script>

<style scoped>
.narrow {
  display: block;
}

.wide {
  display: none;
}

.accessories-wrapper {
  background-color: var(--blue-dk);
  padding: 57px 32px 40px;
}

.inner {
  width: 100%;
  max-width: 720px;
  margin: 0 auto 40px;
}

.h3 {
  color: var(--white);
}

.divider {
  width: 183px;
  border-top: 1px solid var(--olive-lt);
}

::v-deep span {
  border: 1px solid var(--olive-lt);
  background-color: var(--blue-dk);
}

@media screen and (min-width: 940px) {
  .narrow {
    display: none;
  }

  .wide {
    display: block;
    margin-bottom: 55px;
  }

  .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 960px;
    margin: 0 auto 70px;
  }

  .h3 {
    display: flex;
    justify-content: center;
    flex: 0 0 340px;
  }

  .divider {
    width: 100%;
  }
}
</style>
