<template>
<div class="btn-secondary">
  <a :href="url" class="btn">
    <slot>Button</slot>
    <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <!-- <path d="M0.989502 1.70181L6.99991 7.71222L13.0103 1.70181L11.5961 0.287598L6.99991 4.88379L2.40372 0.287598L0.989502 1.70181Z" fill="#FAF6EF"/> -->
      <path d="M0.989502 1.70181L6.99991 7.71222L13.0103 1.70181L11.5961 0.287598L6.99991 4.88379L2.40372 0.287598L0.989502 1.70181Z" fill="#4f6568"/>
    </svg>
  </a>
</div>
</template>

<script>
  export default {
    name: "BtnSecondary",

    props: {
      url: String,
    },
  }
</script>

<style scoped>
.btn {
  background-color: var(--white);
  color: var(--blue-dk);
  border: 1px solid var(--blue-dk);
  font-family: var(--roboto);
  font-style: normal;
  font-weight: 500;
  font-size: 16rem;
  line-height: 1.19;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  padding: 10px 15px 10px 20px;
  text-decoration: none;
  transition: 0.3s;
}

.btn:hover,
.btn:active {
  background-color: var(--blue-dk);
  color: var(--white);
}

.btn:hover svg path,
.btn:active svg path {
  fill: #ffffff;
}

svg {
  margin-left: 25px;
}
</style>