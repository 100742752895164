<template>
    <!-- controls
    controlsList="nodownload" -->
  <section class="video">
    <a href="" @click.prevent="$refs.modal1.openModal()">
      <video
        width="100%"
        autoplay
        muted
        loop
        playsinline
        :poster="`${s3}mundo-banner-home@3x.jpg`"
      >
        <source :src="`${s3}mundo_banner.mp4`" type="video/mp4" />
        Sorry, your browser doesn't support embedded videos.
      </video>
    </a>
    <app-modal ref="modal1">
      <template v-slot:header />
      <template v-slot:body>
        <div class="video-responsive">
          <iframe width="768" height="432" src="https://www.youtube.com/embed/kYHFZgDugrY" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </template>
      <template v-slot:footer />
    </app-modal>
  </section>
</template>

<script>
import AppModal from "@/components/AppModal.vue";

  export default {
    name: "AppVideo",

    components: {
      AppModal,
    },

    props: {
      s3: String
    },
  }
</script>

<style scoped>
.video-responsive{
    overflow:hidden;
    padding-bottom:56.25%;
    position:relative;
    height:0;
}
.video-responsive iframe{
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
}

@media screen and (min-width: 940px) {
  .video {
    margin-bottom: 100px;
  }
}
</style>
