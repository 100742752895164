<template>
  <div class="btn-link">
    <a :href="url" target="_blank" class="btn" download>
      <slot>Button</slot>
    </a>
  </div>
</template>

<script>
  export default {
    name: "BtnLink",

    props: {
      url: String,
    },
  }
</script>

<style scoped>
.btn {
  background-color: var(--blue-dk);
  color: var(--white);
  font-family: var(--roboto);
  font-style: normal;
  font-weight: 500;
  font-size: 16rem;
  line-height: 1.19;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: capitalize;
  border-radius: 30px;
  padding: 15px 30px;
  text-decoration: none;
  transition: 0.3s;
}

.btn:hover,
.btn:active {
  background-color: #2f3e40;
}
</style>