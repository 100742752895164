<template>
  <div class="divider">
    <span :class="type" />
  </div>
</template>

<script>
  export default {
    name: "Divider",

    props: {
      type: String
    },
  }
</script>

<style scoped>
.divider {
  border-top: 1px solid var(--gray5);
  border-bottom: none;
  border-left: none;
  border-right: none;
  width: 100%;
  margin: 10px 0;
  position: relative;
}

span {
  position: absolute;
  top: -3.5px;
  width: 6px;
  height: 6px;
  display: block;
  border-radius: 3px;
  border: 1px solid var(--gray5);
  background-color: var(--white);
}

.left {
  left: 0;
}

.right {
  right: 0;
}
</style>