<template>
  <div class="contact-us">
    <img src="@/assets/images/logo-mundo-v.svg" class="logo" />
    <div class="loading" v-if="loading">
      <img src="https://s3.amazonaws.com/s3.pilates.com/img/mundo-loader.gif" alt="Stay tuned"/>
    </div>

    <div v-else class="wrapper">
      <h1 class="h1">Contact Your Local Mundo Sales Team</h1>
      <p class="small">*All fields are required</p>
      <form>
        <div>
          <label for="firstname">First Name</label><br/>
          <input type="text" id="firstname" name="firstname" v-model="firstname" />
        </div>

        <div>
          <label for="lastname">Last Name</label><br/>
          <input type="text" id="lastname" name="lastname" v-model="lastname" />
        </div>
        
        <div>
          <label for="email">Email</label><br/>
          <input type="email" id="email" name="email" v-model="email" @focusout="validateEmail(email)" />
        </div>

        <div>
          <label for="phone">Phone</label><br/>
          <input type="tel" id="phone" name="phone" v-model="phone" />
        </div>

        <div>
          <label for="country">Country</label><br/>
          <select v-model="country" name="country" id="country">
            <option disabled value="">Please select one</option>
            <option v-for="(country, index) in countries" :key="index" :value="country.value">
              {{ country.label }}
            </option>
          </select>
        </div>
        
        <div class="extra-margin">
          <p>I am a:</p>
          <input type="radio" id="home" value="108700000" v-model="usertype" /><label for="home">Home User</label>
          <br/>
          <input type="radio" id="professional" value="108700001" v-model="usertype" /><label for="professional">Professional User</label>
        </div>
      
        <div>
          <label for="message">Message</label><br/>
          <textarea placeholder="Write your message here." id="message" name="message" maxlength="1000" v-model="message"></textarea>
        </div>

        <div>
          <input
            type="submit"
            value="Submit"
            class="btn"
            :disabled="disabled"
            @click.prevent="submitForm"
          />
        </div>
      </form>

      <p v-if="error.length" class="error">{{ error }}</p>
      <span v-show="alert">{{ alert }}</span>
    </div>
  </div>
</template>

<script>
import ApiService from '@/js/ApiService.js';

export default {
  name: "ContactUs",

  data() {
    return {
      disabled: false,
      loading: false,
      error: "",
      alert: "",
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      country: "",
      usertype: "",
      message: ""
    }
  },

  created() {
    this.fetchCountryList();
  },

  methods: {
    async submitForm() {
      if (this.firstname && this.lastname && this.email && this.country && this.phone && this.usertype && this.message) {
        this.error = "";
        this.alert ="";
        this.loading = true;
        this.disabled = true;
        var data = {
          firstName: this.firstname,
          lastName: this.lastname,
          emailAddress: this.email,
          country: this.country,
          postalCode: "",
          phoneNumber: this.phone,
          category: this.usertype,
          questionType: "",
          question: this.message
        };
        await ApiService.postForm(data)
        .then((response) => {
          this.response = response;
          if (response.status !== 200) {
            this.error = "POST error, status code: " + response.status;
            this.loading = false;
          }
          this.loading = false;
          this.error = "";
          this.alert = "Thank you, your message has been sent. A representative will respond soon."
        })
        .catch((error) => {
          this.error = "An error has occurred: " + error
          this.loading = false;
          this.alert = "";
        });
      }
      else {
        this.error = "All fields are required";
      }
    },

    validateEmail(email) {
      this.error = "";
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(email)) {
        this.error = "A valid email address is required";
      }
    },

    fetchCountryList() {
      this.error = false;
      this.loading = true;
      ApiService.getCountryList()
      .then(response => {
        this.loading = false;
        this.countries = response.data;
      })
      .catch(error => {
        this.loading = false;
        this.error = true;
        this.message = error;
        console.error(error);
      })
    },
  }
}
</script>

<style scoped>
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.wrapper {
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
}

.logo {
  display: none;
}

.small {
  margin: 10px 0 25px;
}

form div {
  margin: 10px 0;
}

.extra-margin {
  margin: 20px 0;
}

label {
  display: inline-block;
  padding-bottom: 5px;
}

input,
select,
textarea {
  padding: 5px;
  width: min(100%, 350px);
}

input[type=radio] {
  width: 25px;
  height: 25px;
  vertical-align: -40%;
  margin: 10px 10px 0;
}

textarea {
  height: 100px;
}

.error {
  color: red;
}

.btn {
  background-color: var(--blue-dk);
  color: var(--white);
  font-family: var(--roboto);
  font-style: normal;
  font-weight: 500;
  font-size: 16rem;
  line-height: 1.19;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: capitalize;
  border-radius: 30px;
  padding: 15px 30px;
  text-decoration: none;
  transition: 0.3s;
  cursor: pointer;
  border: none;
}

.btn:hover,
.btn:active {
  background-color: #2f3e40;
}

@media screen and (min-width: 625px) {
  .contact-us {
    position: relative;
  }

  .logo {
    display: block;
    width: 100px;
    position: absolute;
    top: 0;
    left: 10px;
  }
}
</style>
