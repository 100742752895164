<template>
  <section class="intro">
    <div class="intro-images">
      <img
        :src="`${s3}mundo-detail-1@1x.jpg`"
        :srcset="`
          ${s3}mundo-detail-1@1x.jpg 297w,
          ${s3}mundo-detail-1@2x.jpg 594w,
          ${s3}mundo-detail-1@3x.jpg 891w,
          ${s3}mundo-detail-1@4x.jpg 1188w,
        `"
        sizes="100vw"
        alt="Photo of feet on footbar"
        class="img1"
      />
      <img
        :src="`${s3}mundo-detail-2@1x.jpg`"
        :srcset="`
          ${s3}mundo-detail-2@1x.jpg 297w,
          ${s3}mundo-detail-2@2x.jpg 594w,
          ${s3}mundo-detail-2@3x.jpg 891w,
          ${s3}mundo-detail-2@4x.jpg 1188w,
        `"
        sizes="100vw"
        alt="Product detail photo"
        class="img2"
      />
      <img
        :src="`${s3}mundo-detail-3@1x.jpg`"
        :srcset="`
          ${s3}mundo-detail-3@1x.jpg 297w,
          ${s3}mundo-detail-3@2x.jpg 594w,
          ${s3}mundo-detail-3@3x.jpg 891w,
          ${s3}mundo-detail-3@4x.jpg 1188w,
        `"
        sizes="100vw"
        alt="Product detail photo"
        class="img3"
      />
      <div class="block" />
    </div>
    <div class="intro-content">
      <h1 class="h3">Mundo Equipment Suite</h1>
      <divider type="right" />
      <p class="p1">Best in its class of Pilates equipment, each Mundo piece functions as a User would expect moderately-priced Pilates equipment to perform and feel while exercising.</p>
      <p class="p1">Our apparatus is built with sustainably-grown Verde lyptus wood in a socially-responsible manufacturing facility. Mundo apparatus dimensions are consistent with standards set by Balanced Body. This affordable line of handcrafted quality Pilates equipment includes:</p>
      <p class="p1">
        <a href="#reformer">Reformer</a><br/>
        <a href="#reformertower">Reformer with Tower</a><br/>
        <a href="#cadillac">Cadillac</a><br/>
        <a href="#barrel">Ladder Barrel</a><br/>
        <a href="#chair">Step Chair</a>
      </p>
    </div>
  </section>
</template>

<script>
import Divider from "@/components/Divider.vue";

  export default {
    name: "Intro",

    components: {
      Divider,
    },

    props: {
      s3: String
    },
  }
</script>

<style scoped>
.intro {
  padding: 24px;
}

img {
  display: block;
  width: 100%;
}

.intro-images {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 8px;
  position: relative;
  margin: 40px auto;
  max-width: 558px;
  align-items: stretch;
}

.img1 {
  grid-row: 1 / span 2;
  grid-column: 1 / span 1;
}

.img2 {
  grid-row: 1 / span 1;
  grid-column: 2 / span 1;
}

.img3 {
  grid-row: 2 / span 1;
  grid-column: 2 / span 1;
}

.block {
  width: 50%;
  height: 66%;
  background-color: var(--earth-md);
  position: absolute;
  left: -12px;
  bottom: -12px;
  z-index: -10;
}

.divider {
  margin-bottom: 30px;
}

.intro-content {
  max-width: 550px;
  margin: 0 auto;
}

.p1 {
  margin-bottom: 10px;
}

a {
  color: var(--link-dk);
  display: inline-block;
  margin-bottom: 10px;
}

@media screen and (min-width: 940px) {
  .intro {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 90%;
    max-width: 940px;
    margin: 100px auto 150px;
    padding: 0;
    /* gap: 40px; */
  }

  .intro-images {
    max-width: none;
    flex-basis: 50%;
    margin: 0 20px 0 0;
  }

  .intro-content {
    max-width: none;
    flex-basis: 50%;
    margin-left: 20px;
  }
}
</style>
